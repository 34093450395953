<template>
  <div class="child-page-div">
    <div class="page-content-div">
      <div class="left-div">
        <div class="item-box-div">
          <div class="item-box-title-div text-c">商会成员</div>
          <div class="item-box-msg-div text-c">{{ this.$store.state.statistics.memberCount }}</div>
          <div class="item-box-tips-div text-c">总人数</div>
        </div>
        <div class="more-div">
          <div class="item-box-div">
            <div class="item-box-title-div text-c">入会申请</div>
            <div class="item-box-msg-div text-c">{{ this.$store.state.statistics.applyJoinCount }}</div>
            <div class="item-box-tips-div text-c">申请人数</div>
          </div>
          <div class="item-box-div">
            <div class="item-box-title-div text-c">加入申请</div>
            <div class="item-box-msg-div text-c">{{ this.$store.state.statistics.applyAddCount }}</div>
            <div class="item-box-tips-div text-c">申请人数</div>
          </div>
        </div>
      </div>
      <div class="right-div">
        <div class="firm-item-div">
          <div class="firm-msg-div">
            <label class="firm-item-title-label">商会名称：</label>
            <label class="firm-item-text-label">{{ this.$store.state.user.firm.name }}</label>
          </div>
          <div class="firm-msg-div firm-right-msg-div">
            <label class="firm-item-title-label">商会电话：</label>
            <label class="firm-item-text-label">{{ this.$store.state.user.firm.phone }}</label>
          </div>
        </div>
        <div class="msg-item-div firm-item-div">
          <div class="firm-msg-div">
            <label class="firm-item-title-label">商会地区：</label>
            <label class="firm-item-text-label">{{ this.$store.state.user.firm.prefecture }}</label>
          </div>
          <div class="firm-msg-div firm-right-msg-div">
            <label class="firm-item-title-label">商会邮箱：</label>
            <label class="firm-item-text-label">{{ this.$store.state.user.firm.email }}</label>
          </div>
        </div>
        <div class="msg-item-div item-title-div">商会图片</div>
        <div class="firm-img-div">
          <label class="firm-img-label"><img v-if="Array.isArray(this.$store.state.user.firm.img) && this.$store.state.user.firm.img.length > 0" :src="`${$imgBaseURL}/${this.$store.state.user.firm.img[0]}`" alt=""></label>
          <label class="firm-img-label"><img v-if="Array.isArray(this.$store.state.user.firm.img) && this.$store.state.user.firm.img.length > 1" :src="`${$imgBaseURL}/${this.$store.state.user.firm.img[1]}`" alt=""></label>
          <label class="firm-img-label"><img v-if="Array.isArray(this.$store.state.user.firm.img) && this.$store.state.user.firm.img.length > 2" :src="`${$imgBaseURL}/${this.$store.state.user.firm.img[2]}`" alt=""></label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getFirmInfo } from "../common/api";

export default {
  name: "Main",
  created() {
    this.initData();
  },
  methods: {
    initData() {
      getFirmInfo().then(data => {
        let user = this.$store.state.user;
        user.firm = data
        this.$store.commit("setUser", user);
      }).catch(error => {
        if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
          this.$layer.msg(error.data.msg);
        } else {
          this.$layer.msg("查询信息失败，请稍后重试");
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.child-page-div {
  min-width: 800px;
  max-width: 1200px;
  .page-content-div {
    border-radius: 5px;
    padding: 20px;
    background-color: #f2f2f2;
    display: flex;
    justify-content: space-around;
    .left-div {
      width: 420px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .item-box-div {
        width: 160px;
        height: 180px;
        padding: 10px 0;
        background: #ffffff;
        border-radius: 10px;
        box-shadow: 0 3px 51px 0 rgba(207,207,207,0.40);
        .item-box-title-div {
          font-size: 15px;
          font-weight: bold;
        }
        .item-box-msg-div {
          height: 120px;
          line-height: 120px;
          font-size: 20px;
          font-weight: bold;
        }
        .item-box-tips-div {
          color: #8b8b8b;
        }
      }
      .more-div {
        margin-top: 50px;
        display: flex;
        .item-box-div {
          margin-right: 20px;
          margin-left: 20px;
        }
        .item-box-div:first-child {
          margin-left: 0;
        }
        .item-box-div:last-child {
          margin-right: 0;
        }
      }
    }
    .right-div {
      height: 430px;
      padding: 10px;
      background: #ffffff;
      border-radius: 10px;
      box-shadow: 0 3px 51px 0 rgba(207,207,207,0.40);
      flex: 1;
      .msg-item-div {
        margin-top: 70px;
      }
      .item-title-div {
        font-weight: bold;
      }
      .firm-item-div {
        display: flex;
        justify-content: space-between;
        .firm-item-title-label {
          font-weight: bold;
        }
        .firm-item-text-label {
          color: #767676;
        }
        .firm-msg-div {
          flex: 1;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }
        .firm-right-msg-div {
          padding-left: 20px;
        }
      }
      .firm-img-div {
        margin-top: 30px;
        display: flex;
        justify-content: space-around;
        .firm-img-label {
          flex: 1;
          height: 180px;
          background: #002345;
          border-radius: 10px;
          margin-right: 5px;
          margin-left: 5px;
          img {
            width: 100%;
            height: 100%;
            border-radius: 10px;
          }
        }
      }
    }
  }
}
</style>
